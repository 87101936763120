<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addCustomerStakeholder']">新增客户干系人</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="stakeholderName" slot-scope="text, record">
          <router-link class="text-underline" :to="`customerStakeholderDetail?id=${ record.id }`"  v-if="authority.viewCustomerStakeholderDetail">{{ text }}</router-link>
          <div v-else>{{ text }}</div>
        </template>
        <template slot="totalProjectNum" slot-scope="text, record">
          {{ text }} ({{ record.goingProjectNum }})
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="干系人姓名/客户名称" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="新增客户干系人"
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="干系人姓名" prop="stakeholderName">
              <a-input placeholder="请输入干系人姓名" v-model="form.stakeholderName" />
            </a-form-model-item>
            <a-form-model-item  label="所属客户" prop="customerId">
              <a-select v-model="form.customerId" placeholder="请选择所属客户">
                <a-select-option :value="item.id" v-for="(item) in customerList" :key="item.id">{{ item.customerName }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="干系人级别" prop="stakeholderLevel">
              <a-select v-model="form.stakeholderLevel" placeholder="请选择干系人级别">
                <a-select-option :value="1">主要干系人</a-select-option>
                <a-select-option :value="2">干系人</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="职务" prop="jobTitle">
              <a-input placeholder="请输入职务" v-model="form.jobTitle" />
            </a-form-model-item>
            <a-form-model-item label="联系电话" prop="phone">
              <a-input placeholder="请输入联系电话" v-model="form.phone" />
            </a-form-model-item>
            <a-form-model-item label="邮箱" prop="email">
              <a-input placeholder="请输入邮箱" v-model="form.email" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { createCustomerStakeholder, deleteCustomerStakeholder, getCustomerStakeholderList } from '../../api/customer/customerStakeholder'
import { mapState } from 'vuex'
import { getCustomerList } from '../../api/customer/customer'

export default {
  name: 'customerStakeholderProfile',
  data () {
    return {
      columns: [
        {
          title: '客户干系人姓名',
          dataIndex: 'stakeholderName',
          scopedSlots: { customRender: 'stakeholderName' }
        },
        {
          title: '所属客户',
          dataIndex: 'customerName'
        },
        {
          title: '职务',
          dataIndex: 'jobTitle'
        },
        {
          title: '参与项目',
          dataIndex: 'totalProjectNum',
          scopedSlots: { customRender: 'totalProjectNum' }
        },
        {
          title: '联系电话',
          dataIndex: 'phone'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '修改人',
          dataIndex: 'modifier'
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        stakeholderName: [
          { required: true, message: '请输入干系人姓名', trigger: 'blur' }
        ],
        customerId: [
          { required: true, message: '请选择所属客户', trigger: 'change' }
        ]
      },
      customerList: []
    }
  },
  mounted () {
    this.getList()
    this.getCustomerList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getCustomerStakeholderList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteCustomerStakeholder({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createCustomerStakeholder(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1
      }
      this.districtList = []
      this.getList()
    },
    getCustomerList () {
      getCustomerList({
        pageNum: 1,
        pageSize: 10000
      }).then(res => {
        this.customerList = res.data.list
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped>

</style>
